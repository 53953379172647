import {useState} from 'react';
import experience from '~/assets/experience-plan.png';
import excellence from '~/assets/premium-plan.png';
import {Card, CardContent, CardHeader, CardTitle} from '~/components/ui/card';
import {cn} from '~/components/utils';

export const PricingSelector = () => {
  const [selectedPlan, setSelectedPlan] = useState<string>('EXPERIENCE');

  return (
    <div className="container mx-auto">
      <input type="hidden" name="plan" value={selectedPlan} />
      <h2 className="text-3xl font-bold text-center mb-8">Sélectionnez votre formule</h2>
      <div className="flex flex-col md:flex-row gap-6 justify-center items-stretch">
        {plans.map(plan => (
          <Card
            key={plan.id}
            className={cn(
              'w-full md:w-60 flex flex-col cursor-pointer transition-all duration-200 ease-in-out hover:shadow-lg',
              selectedPlan === plan.id
                ? cn(
                    'ring-2',
                    plan.color === 'primary'
                      ? 'ring-primary'
                      : plan.color === 'secondary'
                        ? 'ring-secondary'
                        : 'ring-tertiary',
                  )
                : '',
            )}
            onClick={() => setSelectedPlan(plan.id)}>
            <CardHeader>
              <div className="flex justify-center">
                <img src={plan.image} alt={`${plan.title} plan`} className="size-26 rounded-xl object-cover" />
              </div>
              <CardTitle className="text-2xl font-bold text-center">{plan.title}</CardTitle>
            </CardHeader>
            <CardContent className="flex-grow">
              <p className="text-xl font-bold text-center mb-4">
                {plan.price}
                <span className="text-sm font-normal">/an</span>
              </p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

const plans = [
  {
    id: 'EXPERIENCE',
    title: 'Experience',
    price: ' 1260€ HT',
    image: experience,
    color: 'primary',
  },
  {
    id: 'PREMIUM',
    title: 'Excellence',
    price: '1500€ HT',
    image: excellence,
    color: 'secondary',
  },
];
